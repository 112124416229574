import { Flex, Pagination, Typography } from 'antd'

function Table({ setPage, data }: any): JSX.Element {
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Имя заявителя
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Категория
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Комментарий
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Продукт
          </Typography.Text>
        </Flex>

        {data?.results?.map((item: any) => (
          <Flex
            key={item?.id}
            className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
          >
            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.user?.first_name ?? '-'} {item?.user?.last_name ?? '-'}
            </Typography.Text>

            <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
              {item?.category?.name ?? 'Нет'}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.comment}
            </Typography.Text>
            {/* 
            <Typography.Text className="text-[#1E1E1E] font-normal text-base">
              {item?.created_at
                ? format(new Date(item?.created_at), 'dd.MM.yyyy HH:mm')
                : 'Не указано'}
            </Typography.Text> */}

            <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
              {item?.product ?? 'Нет'}
            </Typography.Text>
          </Flex>
        ))}

        <Flex className="mt-[24px] pb-[24px] pl-[24px]">
          <Pagination
            defaultCurrent={1}
            pageSize={10}
            total={data?.count}
            onChange={(page) => {
              setPage(page)
            }}
            showSizeChanger={false}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Table
