import { Flex, Typography } from 'antd'
import React from 'react'

export default function DomModal({ data }: any): JSX.Element {
  return (
    <Flex
      vertical
      className="h-full items-center justify-center w-full"
      gap={24}
    >
      <Flex vertical className="w-full mt-[16px]">
        <Flex className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Номер дома
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Количество этажей
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Срок сдачи
          </Typography.Text>

          <Typography.Text className="text-[#A9A9A9] font-normal text-base">
            Статус
          </Typography.Text>
        </Flex>

        {data?.map((item: any) => (
          <Flex className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]">
            <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
              {item?.title}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
              {item?.floor_count}
            </Typography.Text>

            <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#FFF]">
              {item?.deadline}
            </Typography.Text>

            <Typography.Text
              style={{
                color: item?.status === 'active' ? '#58C27D' : '#CE5A67',
                backgroundColor: item?.status === 'active' ? '#DEF3E5' : '#F3DCDF',
              }}
              className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[5px] dark:bg-[#233332]"
            >
              • {item?.status === 'active' ? 'Активен' : 'Не активен'}
            </Typography.Text>
          </Flex>
        ))}
      </Flex>
    </Flex>
  )
}
