import CloseIcon from '@/components/icons/close'
import { deleteJK } from '@/features/builders/api'
import RCModalForm from '@/features/RC/components/RCModalForm'
import useMessage from '@/hooks/useShowMessage'
import { DeleteOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Popconfirm, Typography } from 'antd'
import { useMemo, useState } from 'react'
import { Draggable } from 'react-smooth-dnd'
import DomModal from './DomModal'
import PlanModal from './PlanModal'

export default function JKItem({ item, refetch }: any) {
  const [isOpenJKModal, setIsOpenJKModal] = useState(false)
  const [isOpenDomaModal, setIsOpenDomaModal] = useState(false)
  const [isOpenPlanModal, setIsOpenPlanModal] = useState(false)
  const showMessage = useMessage()

  const allPlans = useMemo(() => {
    return item?.blocks?.map((block: any) => block?.block_apartments).flat()
  }, [item])

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useMutation({
    mutationFn: deleteJK,
    onSuccess: () => {
      showMessage('success', 'Застройщик успешно удален.')
      refetch()
    },
  })

  return (
    <>
      <Draggable key={item?.id}>
        <Flex className="border-[#E5E7EB] border-[1px] border-solid rounded-[8px] p-[12px] bg-[#FFF]">
          <Flex vertical className="w-full items-center">
            <Flex className="w-full items-center justify-between">
              <Typography.Text>
                {item?.name} ({item?.id})
              </Typography.Text>

              <Popconfirm
                title="Вы уверены, что хотите удалить эту ЖК?"
                onConfirm={() => {
                  mutateDelete(item?.id)
                }}
                onCancel={() => {
                  console.log('cancel')
                }}
                okText="Да"
                cancelText="Нет"
              >
                <Button
                  className="m-0 p-0 w-max h-max"
                  type="link"
                  danger
                  loading={isLoadingDelete}
                >
                  <DeleteOutlined
                    style={{
                      color: '#FF4D4F',
                    }}
                  />
                </Button>
              </Popconfirm>
            </Flex>

            <Flex vertical gap={8}>
              <Button
                onClick={() => {
                  setIsOpenJKModal(true)
                }}
                className="w-full rounded-[4px] bg-[#BAE0FF] text-[#1677FF]"
              >
                Посмотреть ЖК
              </Button>

              <Button
                onClick={() => {
                  setIsOpenDomaModal(true)
                }}
                className="w-full rounded-[4px] bg-[#b7eb8f] text-[#52c41a]"
              >
                Посмотреть дома
              </Button>

              <Button
                onClick={() => {
                  setIsOpenPlanModal(true)
                }}
                className="w-full rounded-[4px] bg-[#ffe58f] text-[#faad14]"
              >
                Посмотреть планировки
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Draggable>

      <Modal
        open={isOpenJKModal}
        onOk={() => {
          setIsOpenJKModal(false)
        }}
        onCancel={() => {
          setIsOpenJKModal(false)
        }}
        footer={null}
        width="70%"
        height="max-content"
        closeIcon={
          <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
        }
      >
        <Flex vertical className="w-full overflow-auto">
          <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] w-full border p-[24px]">
            <Typography.Title className="!font-medium !text-2xl !m-0">
              Редактировать жилой комплекс
            </Typography.Title>
          </Flex>

          <Flex className="p-[24px]">
            <RCModalForm id={item?.id} />
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isOpenDomaModal}
        onOk={() => {
          setIsOpenDomaModal(false)
        }}
        onCancel={() => {
          setIsOpenDomaModal(false)
        }}
        footer={null}
        width="70%"
        height="max-content"
        closeIcon={
          <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
        }
      >
        <Flex vertical className="w-full overflow-auto">
          <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] w-full border p-[24px]">
            <Typography.Title className="!font-medium !text-2xl !m-0">
              Посмотреть дома
            </Typography.Title>
          </Flex>

          <Flex className="p-[24px]">
            <DomModal data={item?.blocks} />
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={isOpenPlanModal}
        onOk={() => {
          setIsOpenPlanModal(false)
        }}
        onCancel={() => {
          setIsOpenPlanModal(false)
        }}
        footer={null}
        width="70%"
        height="max-content"
        closeIcon={
          <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
        }
      >
        <Flex vertical className="w-full overflow-auto">
          <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] w-full border p-[24px]">
            <Typography.Title className="!font-medium !text-2xl !m-0">
              Посмотреть планировки
            </Typography.Title>
          </Flex>

          <Flex className="p-[24px]">
            <PlanModal data={allPlans} />
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
