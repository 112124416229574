import StarFilledIcon from '@/components/icons/star-icon'
import { Button, Flex, Skeleton, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'

function AnalyticsTopRieltors({
  data,
  isLoading,
}: {
  data: any
  isLoading: boolean
}): JSX.Element {
  const navigate = useNavigate()

  return (
    <Flex className="bg-[#FFF] rounded-[12px] border border-[#E5E7EB] border-solid overflow-hidden w-full">
      <Flex vertical className="p-[24px] w-full">
        <Flex className="items-center justify-between w-full">
          <Typography.Title className="!font-medium !text-2xl !m-0 p-0">
            Топ-10 риелторов
          </Typography.Title>

          <Button
            onClick={() => {
              navigate('/users')
            }}
            className="border-[#E5E7EB] border border-solid rounded-[8px] text-[#1E1E1E] font-normal text-base"
          >
            Посмотреть больше
          </Button>
        </Flex>

        {isLoading ? (
          <Skeleton active />
        ) : (
          <Flex vertical className="w-full mt-[16px]">
            <Flex className="w-full grid grid-cols-[0.5fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]">
              <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                №
              </Typography.Text>

              <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                Имя
              </Typography.Text>

              <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                Рейтинг
              </Typography.Text>

              <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                Количество объявлений
              </Typography.Text>

              {/* <Typography.Text className="text-[#A9A9A9] font-normal text-base">
                Рейтинг
              </Typography.Text> */}
            </Flex>

            {data?.map((item: any, index: number) => (
              <Flex
                key={item?.id}
                className="w-full items-center grid grid-cols-[0.5fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
              >
                <Typography.Text className="text-[#1E1E1E] font-normal text-base flex items-center gap-[14px]">
                  {index + 1}
                </Typography.Text>

                <Flex className="items-center" gap={12}>
                  {/* <Image src={HomePhoto} preview={false} /> */}
                  <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                    {item?.username}
                  </Typography.Text>
                </Flex>

                <Flex vertical>
                  <Flex gap={8} className="items-center">
                    <Typography.Text className="text-[#1E1E1E] font-medium text-base">
                      {item?.rating}
                    </Typography.Text>

                    <Flex gap={4}>
                      {Array.from({ length: 5 }).map((_, index) => (
                        <StarFilledIcon
                          key={index}
                          style={{
                            color: item?.rating > index ? '#FFC107' : '#E5E7EB',
                            width: '16px',
                            height: '16px',
                          }}
                        />
                      ))}
                    </Flex>
                  </Flex>

                  {/* <Flex>
                    <Typography.Text>{item?.reviews} отзывов</Typography.Text>
                  </Flex> */}
                </Flex>

                <Typography.Link className="font-normal text-base">
                  {item?.total_ads}
                </Typography.Link>

                <Typography.Text className="font-normal text-base">
                  {item?.client_rating}
                </Typography.Text>
              </Flex>
            ))}
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default AnalyticsTopRieltors
