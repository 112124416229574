import requestV3 from "@/utils/axiosV3"

export default async function getListApplies({
  filters,
}: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/contacts',
    method: 'get',
    params: filters,
  })

  return res
}
