/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import requestV3 from '@/utils/axiosV3'

export default async function getListBuilders({ filters }: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/companies',
    method: 'get',
    params: filters,
  })

  return res
}

export async function getBuilderById(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/company/${id}`,
    method: 'get',
  })

  return res
}

export async function createBuilder(data: any): Promise<any> {
  const res: any = await requestV3({
    url: '/home/company',
    method: 'post',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function updateBuilder(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/company-update/${data?.id}`,
    method: 'put',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function updateBuilderPatch(data: any): Promise<any> {
  const res: any = await requestV3({
    url: `/home/company-update/${data?.id}`,
    method: 'patch',
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return res
}

export async function deleteBuilder(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/company/${id}`,
    method: 'delete',
  })

  return res
}

export async function deleteJK(id: string): Promise<any> {
  const res: any = await requestV3({
    url: `/home/building/${id}`,
    method: 'delete',
  })

  return res
}
