/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
import HouseIcon from '@/components/icons/house-icon'
import PlusAddIcon from '@/components/icons/plus-icon'
import PromotionIcon from '@/components/icons/promotion-icon'
import UserIcon from '@/components/icons/user'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'
import { HomeOutlined, SearchOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Breadcrumb,
  Button,
  Empty,
  Flex,
  Input,
  Select,
  Skeleton,
  Typography,
} from 'antd'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import getListBuildings from '../api'
import CreateDrawer from '../components/CreateDrawer'
import Table from '../components/Table'

function ResidentialComplexPage(): JSX.Element {
  const [drawerFilter, setDrawerFilter] = useState(false)
  const { watch, setValue } = useForm()
  const [page, setPage] = useState(1)
  const [activeTab, setActiveTab] = useState('active')

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['buildings', page, watch('filters'), activeTab],
    queryFn: async () =>
      await getListBuildings({
        filters: {
          page,
          status: activeTab === 'active' ? '1' : '2',
          ...watch('filters'),
        },
      }),
      cacheTime: 0,
  })

  const statistics = useMemo(
    () => [
      {
        title: 'Все новостройки',
        value: data?.total_buildings,
        backgroundColor: '#FCE7DB',
        icon: (
          <UserIcon
            style={{
              color: '#FA8F54',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
      {
        title: 'Общее количество предложений',
        value: data?.total_offers,
        backgroundColor: '#E0DEFF',
        icon: (
          <PromotionIcon
            style={{
              color: '#635BFF',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
      {
        title: 'Cредняя цена за м2',
        value: `${Math.round(data?.average_price_per_square)} UZS`,
        backgroundColor: '#DEF3E5',
        icon: (
          <HouseIcon
            style={{
              color: '#58C27D',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
      {
        title: 'Количество заявок',
        value: data?.total_applications,
        backgroundColor: '#DEF3E5',
        icon: (
          <HouseIcon
            style={{
              color: '#58C27D',
              width: '24px',
              height: '24px',
            }}
          />
        ),
      },
    ],
    [data],
  )

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <HomeOutlined />
                <Typography.Text className="text-sm font-normal text-center">
                  Главная
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Жилой комплекси
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-solid border-[1px] rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Жилой комплексы ({data?.buildings?.count ?? 0})
          </Typography.Title>

          <Flex gap={16}>
            <Input
              placeholder="Поиск"
              className="border border-[#E5E7EB] rounded-lg h-auto w-full px-[10px] text-[#A9A9A9] text-sm font-normal min-w-[400px]"
              value={watch('filters.search')}
              onChange={(e) => {
                setValue('filters.search', e.target.value)
              }}
              prefix={
                <Flex className="mr-[10px]">
                  <SearchOutlined
                    style={{
                      color: '#A9A9A9',
                    }}
                  />
                </Flex>
              }
            />

            <Button
              onClick={() => {
                setDrawerFilter(true)
              }}
              className="border-none bg-[#635BFF] h-[48px] rounded-[8px] flex gap-[10px] items-center !text-[#FFF] !font-medium !text-base hover:!bg-[#635BFF]"
            >
              <PlusAddIcon
                style={{
                  color: '#FFF',
                }}
              />
              Добавить жилой комплекс
            </Button>

            <Select
              defaultValue="today"
              value={watch('filters.filter_type')}
              onChange={(value) => {
                setValue('filters.filter_type', value)
              }}
              className="border border-[#E5E7EB] border-solid rounded-lg h-auto w-full text-[#A9A9A9] text-sm font-normal min-w-[140px]"
              options={[
                { label: 'В этот день', value: 'today' },
                { label: 'На этой неделе', value: 'week' },
                { label: 'В этом году', value: 'year' },
              ]}
            />
          </Flex>
        </Flex>

        <Flex className="w-max m-[24px] mb-0">
          <Flex className="p-[2px] rounded-[10px] bg-[#F1F2F4]">
            <Button
              style={{
                backgroundColor: activeTab === 'active' ? '#FFF' : '#F1F2F4',
              }}
              onClick={() => {
                setActiveTab('active')
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Активные
            </Button>

            <Button
              onClick={() => {
                setActiveTab('closed')
              }}
              style={{
                backgroundColor: activeTab === 'closed' ? '#FFF' : '#F1F2F4',
              }}
              className="p-[13px_37px] rounded-[10px] text-[#1E1E1E] text-base font-medium h-auto"
            >
              Закрытые
            </Button>
          </Flex>
        </Flex>

        <Flex className="w-full h-full">
          <Flex vertical className="w-full pb-[24px]">
            <Flex className="p-[24px] grid grid-cols-4 w-full" gap={24}>
              {statistics.map((item: any, index: number) => (
                <Flex
                  key={item.title}
                  vertical
                  className="p-[24px] rounded-[12px] border border-[#E5E7EB] border-solid bg-[#FFF] overflow-hidden"
                >
                  <Flex
                    style={{ backgroundColor: item.backgroundColor }}
                    className="w-[40px] h-[40px] items-center justify-center rounded-full"
                  >
                    {item.icon}
                  </Flex>

                  <Flex
                    vertical
                    className="mt-[24px] mb-[16px] overflow-hidden"
                  >
                    <Typography.Text className="text-[#878787] font-normal text-sm mb-[8px] text-nowrap ">
                      {item.title}
                    </Typography.Text>

                    {isLoading ? (
                      <Skeleton.Button
                        active
                        size="small"
                        shape="square"
                        block
                      />
                    ) : (
                      <Typography.Text className="text-[#1E1E1E] font-medium text-[1.5vw]">
                        {formatNumberWithSpaces(item.value)}
                      </Typography.Text>
                    )}
                  </Flex>
                </Flex>
              ))}
            </Flex>

            {isLoading ? (
              <Flex className="p-[24px]">
                <Skeleton active />
              </Flex>
            ) : data?.buildings?.results?.length === 0 ? (
              <Flex
                vertical
                className="w-full h-full items-center justify-center"
              >
                <Empty description="Нет данных" />
              </Flex>
            ) : (
              <Table
                data={data?.buildings}
                setPage={setPage}
                page={page}
                refetch={refetch}
              />
            )}
          </Flex>
        </Flex>
      </Flex>

      <CreateDrawer
        drawerFilter={drawerFilter}
        setDrawerFilter={setDrawerFilter}
        refetch={refetch}
      />
    </Flex>
  )
}

export default ResidentialComplexPage
