import UrboIcon from '@/components/icons/urbo-icon'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb, Flex, Skeleton, Typography } from 'antd'
import { useState } from 'react'
import getListComplaints from '../api'
import Table from '../components/Table'

function ComplaintsPage(): JSX.Element {
  const [page, setPage] = useState(1)

  const { data, isLoading } = useQuery({
    queryKey: ['complaints-list', page],
    queryFn: async () =>
      await getListComplaints({
        filters: {
          page,
          page_size: 10,
        },
      }),
      cacheTime: 0,
  })

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Breadcrumb
        items={[
          {
            href: '/',
            title: (
              <Flex className="items-center h-full" gap={10}>
                <UrboIcon />
                <Typography.Text className="text-sm font-normal text-center">
                  Urbo
                </Typography.Text>
              </Flex>
            ),
          },
          {
            title: (
              <Typography.Text className="text-[#1E1E1E] text-sm font-normal">
                Жалобы
              </Typography.Text>
            ),
          },
        ]}
      />

      <Flex
        vertical
        className="border-[#E5E7EB] border-[1px] border-solid rounded-[16px] bg-[#FFF] mt-[32px]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-b-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
            Жалобы
          </Typography.Title>
        </Flex>

        <Flex className="w-full h-full p-[0px_24px]">
          {isLoading ? (
            <Flex className="w-full h-full">
              <Skeleton active />
            </Flex>
          ) : (
            <Table data={data} setPage={setPage} />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ComplaintsPage
