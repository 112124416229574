import { deleteBuilder } from '@/features/builders/api'
import { updateRCPage } from '@/features/RC/api'
import useMessage from '@/hooks/useShowMessage'
import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Button, Divider, Flex, Popconfirm, Spin, Typography } from 'antd'
import { useSearchParams } from 'react-router-dom'
import { Container } from 'react-smooth-dnd'
import getListData from '../api'
import JKItem from '../components/JKItem'

function ComparePage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const ids = searchParams.get('ids')
  const showMessage = useMessage()

  const { data, isFetching, refetch } = useQuery({
    cacheTime: 0,
    queryKey: ['builders-compare', ids],
    queryFn: async () =>
      await getListData({
        ids: ids,
      }),
  })

  const { mutate: mutateDeleteBuilder, isLoading: isLoadingDeleteBuilder } =
    useMutation({
      mutationFn: deleteBuilder,
      onSuccess: () => {
        showMessage('success', 'Застройщик успешно удален.')
        refetch()
      },
    })

  const { mutate: mutateUpdate } = useMutation({
    mutationFn: updateRCPage,
    onSuccess: () => {
      showMessage('success', 'Успешно изменено')
      refetch()
    },
    onError: (error: Error) => {
      console.error(error)
    },
  })

  const onDrop = (dropResult: any, itemId: number) => {
    const { addedIndex, removedIndex, payload } = dropResult

    // Prevent double trigger by handling only when the item is added or removed
    if (removedIndex !== null && addedIndex === null) {
      // Only handle when the item is removed from the old container
      console.log('Removed from container:', itemId, payload.id)
    } else if (addedIndex !== null) {
      // Only handle when the item is added to the new container
      console.log('Added to container:', itemId, payload.id)

      mutateUpdate({
        id: payload.id,
        organization: itemId,
      })
    }
  }

  return (
    <Flex vertical className="bg-[#FCFCFD] h-full w-full p-[24px]">
      <Flex
        vertical
        className="border-[#E5E7EB] border-[1px] border-solid rounded-[16px] bg-[#FFF]"
      >
        <Flex className="p-[24px] border-x-0 border-t-0 border-b-[1px] border-[#E5E7EB] border-solid items-center justify-between w-full">
          <Flex className="items-center" gap={12}>
            <Button
              type="text"
              onClick={() => {
                window.history.back()
              }}
            >
              <ArrowLeftOutlined />
            </Button>
            <Typography.Title className="!text-[#1E1E1E] !font-medium !text-2xl !m-0">
              Сравниваем застройщиков
            </Typography.Title>
          </Flex>
        </Flex>

        <Spin spinning={isFetching}>
          <Flex className="w-full h-full p-[24px]">
            <Flex className="" gap={16}>
              {data?.results?.map((item: any) => (
                <Flex
                  key={item.id}
                  vertical
                  className="border-[#E5E7EB] border-[1px] border-solid rounded-[8px] p-[12px]"
                >
                  <Flex className="justify-between items-center">
                    <Typography.Text className="font-bold">
                      {item?.name} ({item?.id})
                    </Typography.Text>

                    <Popconfirm
                      title="Вы уверены, что хотите удалить этот застройщик?"
                      onConfirm={() => {
                        mutateDeleteBuilder(item.id)
                      }}
                      onCancel={() => {
                        console.log('cancel')
                      }}
                      okText="Да"
                      cancelText="Нет"
                    >
                      <Button
                        className="m-0 p-0 w-max h-max"
                        type="link"
                        danger
                        loading={isLoadingDeleteBuilder}
                      >
                        <DeleteOutlined
                          style={{
                            color: '#FF4D4F',
                          }}
                        />
                      </Button>
                    </Popconfirm>
                  </Flex>

                  <Divider className="m-0 mt-[8px]" />

                  <Flex vertical gap={12} className="mt-[12px]">
                    <Container
                      key={item.id}
                      onDrop={(e) => onDrop(e, item.id)}
                      getChildPayload={(index) => item.buildings[index]}
                      groupName="col"
                      orientation="vertical"
                      shouldAcceptDrop={() => true}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        minHeight: '100px',
                        border:
                          item.buildings.length === 0
                            ? '2px dashed #d9d9d9'
                            : 'none',
                        padding: '10px',
                        minWidth: '240px',
                      }}
                    >
                      {item.buildings.length === 0 ? (
                        <Typography.Text
                          type="secondary"
                          className="flex items-center justify-center"
                        >
                          Drop items here
                        </Typography.Text>
                      ) : (
                        item.buildings.map((jkItem: any) => (
                          <JKItem
                            key={jkItem.id}
                            item={jkItem}
                            refetch={refetch}
                          />
                        ))
                      )}
                    </Container>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Spin>
      </Flex>
    </Flex>
  )
}

export default ComparePage
