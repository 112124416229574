import BlurImage from '@/components/BlurImage'
import formatAmount from '@/helpers/formatAmount'
import { Flex, Typography } from 'antd'

export default function PlanModal({ data }: any): JSX.Element {
  return (
    <Flex vertical className="w-full">
      <Flex vertical className="h-full items-center justify-center" gap={24}>
        <Flex vertical className="w-full mt-[16px]">
          <Flex
            gap={10}
            className="w-full grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
          >
            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Планировка
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Общая площадь
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Цена за м2
            </Typography.Text>

            <Typography.Text className="text-[#A9A9A9] font-normal text-base">
              Доступно
            </Typography.Text>
          </Flex>

          {data?.map((item: any) => (
            <Flex
              gap={10}
              className="w-full items-center grid grid-cols-[1fr_1fr_1fr_1fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px] dark:border-[#272B30]"
            >
              <Flex className="cursor-pointer">
                <BlurImage src={item?.image} width={68} height={68} />
              </Flex>

              <Typography.Text className="text-[#1E1E1E] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#E5E7EB] h-max w-max flex items-center dark:bg-[#272B30] dark:text-[#FFF]">
                {item?.total_space}
              </Typography.Text>

              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
                {formatAmount(item?.price ?? 0)} uzs
              </Typography.Text>

              <Typography.Text className="text-[#1E1E1E] font-medium text-base dark:text-[#A9A9A9]">
                {item?.count} шт.
              </Typography.Text>
            </Flex>
          ))}

          {/* <Flex className="mt-[24px]">
            <Pagination
              defaultCurrent={page}
              pageSize={20}
              total={data?.count}
              onChange={(pageEvent) => {
                setPage(pageEvent)
              }}
              showSizeChanger={false}
            />
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  )
}
