import AdsPage from '@/features/ads/views'
import AdvantagesPage from '@/features/advantages/views'
import Analytics from '@/features/analytics/views'
import AppliesPage from '@/features/applies/views'
import Login from '@/features/auth/views/login'
import BankInfoPage from '@/features/bank/views/BankInfoPage'
import BankPage from '@/features/bank/views/BankPage'
import BannersPage from '@/features/banners/views'
import BuildersPage from '@/features/builders/views'
import ComplaintsPage from '@/features/complaints/views'
import ContactsPage from '@/features/contacts/views'
import EmployeesPage from '@/features/employees/views'
import FaqPage from '@/features/faq/views'
import InstructionsPage from '@/features/instructions/views'
import NewsPage from '@/features/news/views'
import NewsForm from '@/features/news/views/NewsForm'
import ProfilePage from '@/features/profile/views'
import ResidentialComplexPage from '@/features/RC/views'
import RolesPage from '@/features/roles/views'
import RsStatistics from '@/features/rs-statistics/views'
import SeoTemplatesPage from '@/features/seoTemplate/views'
import SeoTemplatesGeneralPage from '@/features/seoTemplateGeneral/views'
import ServicesPage from '@/features/services/views'
import SpecialOffersPage from '@/features/special-offers/views'
import UserPage from '@/features/users/views'
import VacansiesPage from '@/features/vacansies/views'
import ResponsesPage from '@/features/vacansies/views/ResponsesPage'
import VacansyForm from '@/features/vacansies/views/VacansyForm'
import type { CustomRoute } from '@/types'
import CKEditorPage from '@/views/CKEditorPage'
import Error from '@/views/error'
import NoPermission from '@/views/no-permission'
import NotFound from '@/views/not-found'
import Root from '@/views/root'
import { Navigate } from 'react-router-dom'
import AuthRoute from './AuthRoute'
import ComparePage from '@/features/compare/views'

const routes: CustomRoute[] = [
  {
    id: 'global-not-found',
    title: 'Not found',
    path: '*',
    element: <NotFound />,
  },
  {
    id: 'login',
    title: 'Login',
    path: 'login',
    element: <Login />,
  },
  {
    id: 'root',
    title: 'URBO GLOBAL',
    path: '/',
    element: <Root getRoutes={() => routes} />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <Navigate to="/analytics" />,
      },
      {
        id: 'analytics',
        title: 'Аналитика',
        path: 'analytics',
        element: <AuthRoute element={Analytics} keyId="analytics" />,
      },
      {
        id: 'builders',
        title: 'Застройщики',
        path: 'builders',
        element: <AuthRoute element={BuildersPage} keyId="builders" />,
      },
      {
        id: 'compare',
        title: 'Сравнить',
        path: 'compare',
        element: <AuthRoute element={ComparePage} keyId="compare" />,
      },
      {
        id: 'residential-complex',
        title: 'Жилой комплекс',
        path: 'residential-complex',
        element: (
          <AuthRoute
            element={ResidentialComplexPage}
            keyId="residential-complex"
          />
        ),
      },
      {
        id: 'banks',
        title: 'Банки',
        path: 'banks',
        children: [
          {
            id: 'banks-list',
            path: '',
            element: <AuthRoute element={BankPage} keyId="banks" />,
            children: [
              {
                id: 'bank-info',
                path: ':id',
                element: <AuthRoute element={BankInfoPage} keyId="banks" />,
              },
            ],
          },
        ],
      },
      {
        id: 'users',
        title: 'Пользователи',
        path: 'users',
        element: <AuthRoute element={UserPage} keyId="users" />,
      },
      {
        id: 'ads',
        title: 'Объявления',
        path: 'ads',
        element: <AuthRoute element={AdsPage} keyId="ads" />,
      },
      {
        id: 'roles',
        title: 'Роли',
        path: 'roles',
        element: <AuthRoute element={RolesPage} keyId="roles" />,
      },
      {
        id: 'employees',
        title: 'Сотрудники',
        path: 'employees',
        element: <AuthRoute element={EmployeesPage} keyId="employees" />,
      },
      {
        id: 'vacansies',
        title: 'Ваканция',
        path: 'vacansies',
        children: [
          {
            id: 'vacansies-list',
            path: '',
            element: <AuthRoute element={VacansiesPage} keyId="vacansies" />,
          },
          {
            id: 'vacansy-form',
            path: ':id',
            element: <AuthRoute element={VacansyForm} keyId="vacansies" />,
          },
          {
            id: 'responses',
            path: ':id/responses',
            element: <AuthRoute element={ResponsesPage} keyId="vacansies" />,
          },
        ],
      },
      {
        id: 'applies',
        title: 'Заявки',
        path: 'applies',
        element: <AuthRoute element={AppliesPage} keyId="applies" />,
      },
      {
        id: 'complaints',
        title: 'Жалобы',
        path: 'complaints',
        element: <AuthRoute element={ComplaintsPage} keyId="complaints" />,
      },
      {
        id: 'payment-systems',
        title: 'Платежные системы',
        path: 'payment-systems',
        element: <>Платежные системы</>,
      },
      {
        id: 'faq',
        title: 'Вопросы ответы',
        path: 'faq',
        element: <AuthRoute element={FaqPage} keyId="faq" />,
      },
      {
        id: 'services',
        title: 'Услуги',
        path: 'services',
        element: <AuthRoute element={ServicesPage} keyId="services" />,
      },
      {
        id: 'news',
        title: 'Новости',
        path: 'news',
        children: [
          {
            id: 'news-list',
            path: '',
            element: <AuthRoute element={NewsPage} keyId="news" />,
          },
          {
            id: 'news-form',
            path: ':id',
            element: <AuthRoute element={NewsForm} keyId="newsForm" />,
          },
        ],
      },
      {
        id: 'instructions',
        title: 'Инструкции',
        path: 'instructions',
        element: <AuthRoute element={InstructionsPage} keyId="instructions" />,
      },
      {
        id: 'contacts',
        title: 'Контакты',
        path: 'contacts',
        element: <AuthRoute element={ContactsPage} keyId="contacts" />,
      },
      {
        id: 'advantages',
        title: 'Преимущества',
        path: 'advantages',
        element: <AuthRoute element={AdvantagesPage} keyId="advantages" />,
      },
      {
        id: 'special-offers',
        title: 'Спец предложения',
        path: 'special-offers',
        element: (
          <AuthRoute element={SpecialOffersPage} keyId="special-offers" />
        ),
      },
      {
        id: 'banners',
        title: 'Баннеры',
        path: 'banners',
        element: <AuthRoute element={BannersPage} keyId="banners" />,
      },
      {
        id: 'sale-ads',
        title: 'Продажа объявлений',
        path: 'sale-ads',
        element: <>Продажа объявлений</>,
      },
      {
        id: 'residential-complex-statistic',
        title: 'Статистика по ЖК',
        path: 'residential-complex-statistic',
        element: (
          <AuthRoute
            element={RsStatistics}
            keyId="residential-complex-statistic"
          />
        ),
      },
      {
        id: 'seo-template',
        title: 'Шаблон для отдельной страницы',
        path: 'seo-template',
        element: <AuthRoute element={SeoTemplatesPage} keyId="seo-template" />,
      },
      {
        id: 'seo-general-template',
        title: 'Общий шаблон',
        path: 'seo-general-template',
        element: (
          <AuthRoute
            element={SeoTemplatesGeneralPage}
            keyId="seo-general-template"
          />
        ),
      },
      {
        id: 'profile',
        title: 'Профиль',
        path: 'profile',
        element: <AuthRoute element={ProfilePage} keyId="profile" />,
      },

      // OLD ********************

      // {
      //   id: 'clients',
      //   title: 'Клиенты',
      //   path: 'clients',
      //   element: <AuthRoute element={Clients} keyId="clients" />,
      // },
      // {
      //   id: 'reviews',
      //   title: 'Отзывы',
      //   path: 'reviews',
      //   element: <AuthRoute element={Reviews} keyId="reviews" />,
      // },
      // {
      //   id: 'settings',
      //   title: 'Настройки',
      //   path: '1',
      //   element: <AuthRoute element={FlatsPage} keyId="settings" />,
      // },
      // {
      //   id: 'settings2',
      //   title: 'Настройки',
      //   path: '2',
      //   element: <AuthRoute element={DetailPage} keyId="settings2" />,
      // },
      {
        id: 'no-permission',
        title: 'No permission',
        path: 'no-permission',
        element: <NoPermission />,
      },
      {
        id: 'ck-editor',
        title: 'CK Editor',
        path: 'ck-editor',
        element: <CKEditorPage />,
      },
    ],
  },
]

export default routes
