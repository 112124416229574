/* eslint-disable @typescript-eslint/no-floating-promises */
import BlurImage from '@/components/BlurImage'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Checkbox, Flex, Popover, Typography } from 'antd'
import { useState } from 'react'
import { deleteBuilder, updateBuilderPatch } from '../api'
import AddBuilderModal from './AddBuilderModal'

export default function TableRow({ item, refetch, setSelectedBuilders }: any): JSX.Element {
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [open, setOpen] = useState(false)
  const showMessage = useMessage()

  const { mutate: mutateUpdate, isLoading: isLoadingUpdating } = useMutation({
    mutationFn: updateBuilderPatch,
    onSuccess: () => {
      setAddModalOpen(false)
      showMessage('success', 'Застройщик успешно изменен.')
      refetch()
    },
  })

  const { mutate: mutateDelete, isLoading: isLoadingDeleting } = useMutation({
    mutationFn: deleteBuilder,
    onSuccess: () => {
      setAddModalOpen(false)
      showMessage('success', 'Застройщик успешно удален.')
      refetch()
    },
  })

  return (
    <>
      <Flex
        key={item?.id}
        className="w-full items-center grid grid-cols-[0.2fr_1fr_1fr_1fr_1fr_1fr_0.5fr] border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
      >
        <Checkbox className="m-0" onChange={(e) => {
          if (e.target.checked) {
            setSelectedBuilders((prev: any) => [...prev, item?.id])
          }
        }}/>

        <Flex className="items-center" gap={12}>
          <Flex className="w-[48px] h-[48px] overflow-hidden rounded-full border-solid border-[#E5E7EB] items-start justify-center">
            <BlurImage
              src={item?.image}
              alt="logo"
              width="100%"
              height="100%"
              className="w-full h-full object-cover"
            />
          </Flex>
          <Typography.Text className="text-[#635BFF] font-normal text-base">
            {item?.name} {item?.id}
          </Typography.Text>
        </Flex>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base">
          {item?.date}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base">
          {item?.completed_buildings_count}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base">
          {item?.buildings_count}
        </Typography.Text>

        <Typography.Text
          style={{
            color: item?.status ? '#58C27D' : '#CE5A67',
            backgroundColor: item?.status ? '#DEF3E5' : '#F3DCDF',
          }}
          className="font-normal text-xs p-[6px_10px] rounded-[14px] h-max w-max flex items-center gap-[2px]"
        >
          • {item?.status ? 'Активный' : 'Заблокирован'}
        </Typography.Text>

        <Popover
          content={
            <Flex vertical className="min-w-[170px]">
              <Button
                type="link"
                onClick={() => {
                  setAddModalOpen(true)
                  setOpen(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Изменить
              </Button>
              <Button
                type="link"
                loading={isLoadingUpdating}
                onClick={() => {
                  mutateUpdate({ id: item?.id, status: !item?.status })
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                {item?.status ? 'Заблокировать' : 'Активировать'}
              </Button>
              <Button
                type="link"
                loading={isLoadingDeleting}
                onClick={() => {
                  mutateDelete(item?.id)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Удалить
              </Button>
            </Flex>
          }
          placement="bottomRight"
          trigger="click"
          open={open}
          onOpenChange={() => {
            setOpen(!open)
          }}
        >
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
            <MoreOutlined />
          </Button>
        </Popover>
      </Flex>

      <AddBuilderModal
        addModalOpen={addModalOpen}
        setAddModalOpen={setAddModalOpen}
        item={item}
        refetch={refetch}
      />
    </>
  )
}
