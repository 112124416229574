import requestV3 from '@/utils/axiosV3'

export default async function getListData({ ids }: any): Promise<any> {
  console.log('ids', ids)

  const res: any = await requestV3({
    url: '/home/building-filter-by-companies',
    method: 'get',
    params: {
      ids: ids,
    },
  })
  return res
}
