/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import CheckmarkCircleIcon from '@/components/icons/checkmark-circle'
import CloseIcon from '@/components/icons/close'
import IgnoreIcon from '@/components/icons/ignore-icon'
import StarFilledIcon from '@/components/icons/star-icon'
import { formatNumberWithSpaces } from '@/helpers/formatNumberWithSpaces'
import useMessage from '@/hooks/useShowMessage'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Modal, Popover, Typography } from 'antd'
import { useState } from 'react'
import { updateRCPage } from '../api'
import RCModalForm from './RCModalForm'

export default function TableRow({ item, refetch }: any): JSX.Element {
  const [openModal, setOpenModal] = useState(false)
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const showMessage = useMessage()

  const { mutate, isLoading } = useMutation(updateRCPage, {
    onSuccess: () => {
      refetch()
      setIsPopoverVisible(false)
    },
    onError: () => {
      showMessage('error', 'Ошибка!')
    },
  })

  return (
    <>
      <Flex
        className="w-max items-center border border-y-1 border-x-0 border-t-0 border-solid border-[#E5E7EB] py-[14px] px-[20px]"
        key={item?.id}
      >
        <Typography.Text
          onClick={() => {
            window.open(
              `https://testclient.urbo.uz/developers/${item?.organization?.slug}`,
            )
          }}
          className="text-[#635BFF] font-normal text-base min-w-[300px] w-[300px] cursor-pointer"
        >
          {item?.name} {item?.id}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.user?.username}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.organization?.phone}
        </Typography.Text>

        {/* <Flex className="min-w-[300px] w-[300px] items-center" gap={10}>
            <Typography.Text className="text-[#1E1E1E] font-normal text-base text-nowrap">
              {item?.user?.first_password}
            </Typography.Text>
            <Button className="gap-[8px] flex items-center rounded-[32px] !bg-[#F1F2F4] !text-[#1E1E1E] text-base font-normal">
              <RefreshIcon />
              Cбросить
            </Button>
          </Flex> */}

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.deadline}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.organization?.address}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.district?.name}
        </Typography.Text>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {formatNumberWithSpaces(item?.price)}
        </Typography.Text>

        <Flex gap={2} className="min-w-[300px] w-[300px]">
          {Array.from({ length: 5 }).map((_, index) => (
            <StarFilledIcon
              key={index}
              style={{
                color: item?.likes > index ? '#FFC107' : '#E5E7EB',
                width: '20px',
                height: '20px',
              }}
            />
          ))}
        </Flex>

        <Flex className="min-w-[300px] w-[300px]">
          {item?.status === 1 ? (
            <Typography.Text className="text-[#58C27D] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#DEF3E5] h-max w-max flex items-center gap-[2px]">
              • Активный
            </Typography.Text>
          ) : (
            <Typography.Text className="text-[#CE5A67] font-normal text-xs p-[6px_10px] rounded-[14px] bg-[#F3DCDF] h-max w-max flex items-center gap-[2px]">
              • Заблокирован
            </Typography.Text>
          )}
        </Flex>

        <Typography.Text className="text-[#1E1E1E] font-normal text-base min-w-[300px] w-[300px]">
          {item?.organization?.name}
        </Typography.Text>

        {/* <Flex className="min-w-[300px] w-[300px]">
          <Button
            onClick={() => {
              setOpenModal(true)
            }}
            className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center"
          >
            <EditOutlined />
          </Button>
        </Flex> */}

        <Popover
          content={
            <Flex vertical className="min-w-[170px]">
              {item?.status === 1 ? (
                <Button
                  type="link"
                  onClick={() => {
                    setIsPopoverVisible(false)
                    mutate({ id: item?.id, status: 2 })
                  }}
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  <IgnoreIcon />
                  Заблокировать
                </Button>
              ) : (
                <Button
                  type="link"
                  loading={isLoading}
                  onClick={() => {
                    mutate({ id: item?.id, status: 1 })
                  }}
                  className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
                >
                  <CheckmarkCircleIcon
                    style={{
                      color: '#58C27D',
                    }}
                  />
                  Активировать
                </Button>
              )}
              <Button
                type="link"
                loading={isLoading}
                onClick={() => {
                  setOpenModal(true)
                  setIsPopoverVisible(false)
                }}
                className="text-[#1E1E1E] font-normal text-sm p-[12px] rounded-[4px] h-auto flex items-center justify-start"
              >
                Изменить
              </Button>
            </Flex>
          }
          open={isPopoverVisible}
          onVisibleChange={(visible) => {
            setIsPopoverVisible(visible)
          }}
          placement="bottomRight"
          trigger="click"
        >
          <Button className="border-[#E5E7EB] rounded-[8px] h-[38px] w-[38px] p-0 flex items-center justify-center">
            <MoreOutlined />
          </Button>
        </Popover>
      </Flex>

      <Modal
        open={openModal}
        onOk={() => {
          setOpenModal(false)
        }}
        onCancel={() => {
          setOpenModal(false)
        }}
        footer={null}
        width="70%"
        height="max-content"
        closeIcon={
          <CloseIcon className="border-2 flex justify-center items-center w-10 h-10 hover:bg-secondary-light/10 duration-300 !border-red-500 rounded-full bg-[#F1F2F4]" />
        }
      >
        <Flex vertical className="w-full overflow-auto">
          <Flex className="border-x-0 border-t-0 border-solid border-[#E5E7EB] w-full border p-[24px]">
            <Typography.Title className="!font-medium !text-2xl !m-0">
              Редактировать жилой комплекс
            </Typography.Title>
          </Flex>

          <Flex className="p-[24px]">
            <RCModalForm id={item?.id} />
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}
